<div class="loading" *ngIf="loadingCount">
      <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="150px" viewBox="0 0 100 100"
            enable-background="new">
            <g transform="translate(0 -197)">
                  <ellipse cx="50" cy="232.655" rx="12.545" ry="1.938" fill="#01cd6a" />
                  <path d="M31.386 246.9c7.247-5.498-.828-18.32 7.276-17.293v-5.67c-15.594-.441-3.403 19.427-12.662 20.317v5.386c10.068.685-3.1 21.135 12.662 20.41v-5.858c-7.815 1.549-.35-11.039-7.276-17.292zM68.614 246.9c-7.247-5.498.828-18.32-7.276-17.293v-5.67c15.594-.441 3.403 19.427 12.662 20.317v5.386c-10.068.685 3.1 21.135-12.662 20.41v-5.858c7.815 1.549.35-11.039 7.276-17.292z"
                        fill="black" />
                  <path d="M28.5 233.676c-8.144.922-8.233 1.134-8.233 13.324s.497 12.783 7.949 13.324c-4.986-2.068-4.552-4.604-4.498-13.324.054-8.72-.519-11.335 4.781-13.324z"
                        fill="black" />
                  <path style="line-height:1.25" class="t1" fill="#01cd6a"
                        d="M43.275 227.188c-.034.003-.095.009-.181.015-.776.086-1.402.406-1.877.961-.51.6-.761 1.389-.754 2.367.007.917.23 1.647.668 2.192.389.482 1.043.802 1.963.96l.113.016c.01.003.023.003.037 0 .014.003.024.003.031 0 .004.003.012.003.026 0a.07.07 0 00.035 0 .672.672 0 01.113-.015c.858-.07 1.514-.39 1.965-.961.437-.552.659-1.282.662-2.192-.003-.995-.256-1.785-.756-2.367-.468-.544-1.092-.864-1.87-.96l-.175-.016zm0 .851c.383-.003.708.204.977.621.272.41.408 1.005.408 1.783 0 .765-.136 1.352-.408 1.762-.293.458-.618.688-.977.688-.368 0-.7-.23-.992-.688-.265-.41-.398-.997-.398-1.762 0-.778.133-1.373.398-1.783.269-.417.6-.624.992-.62z" />
                  <path style="line-height:1.25" class="t3" fill="#01cd6a"
                        d="M52.51 230.932a1.098 1.098 0 00-.81.385c-.201.236-.3.546-.298.931.003.361.092.65.264.864.153.19.411.316.774.378l.043.006a.04.04 0 00.015 0 .023.023 0 00.012 0h.01a.04.04 0 00.015 0 .199.199 0 01.043-.006c.338-.027.596-.153.774-.378.172-.218.26-.505.261-.864-.001-.392-.1-.702-.296-.931a1.118 1.118 0 00-.739-.38c-.032-.002-.055-.003-.068-.005zm0 .336c.15-.001.279.08.385.244.107.161.16.396.16.703 0 .301-.053.532-.16.693-.116.18-.244.272-.385.272-.145 0-.275-.091-.39-.272-.105-.161-.157-.392-.157-.693 0-.307.052-.542.156-.703.106-.164.236-.246.39-.244zM47.113 230.403a1.093 1.093 0 00-.81.385c-.202.236-.3.546-.298.931.003.361.09.65.262.864.153.19.411.316.773.379a1.212 1.212 0 00.059.006.03.03 0 00.014 0h.023a.22.22 0 01.045-.006c.338-.027.596-.154.773-.38.173-.216.26-.504.262-.863-.001-.392-.102-.702-.299-.931a1.114 1.114 0 00-.804-.385zm0 .336c.15-.001.279.08.384.244.108.161.16.396.16.703 0 .301-.052.532-.16.694-.115.18-.243.269-.384.269-.146 0-.276-.09-.391-.27-.104-.161-.158-.392-.158-.693 0-.307.054-.542.158-.703.106-.164.236-.245.39-.244z" />
                  <path style="line-height:1.25" class="t2" fill="#01cd6a"
                        d="M56.038 230.622a.244.244 0 00-.178.086.915.915 0 01-.328.22.245.245 0 00-.135.126c-.036.083-.035.144.004.182l.004.004a.234.234 0 00.121.119.2.2 0 00.174.004c.069-.03.138-.063.205-.1v1.723c0 .1.03.18.092.244.028.028.102.043.223.043h.01c.119-.001.191-.016.218-.043a.325.325 0 00.092-.244v-2.143a.214.214 0 00-.063-.156.223.223 0 00-.16-.064zM48.916 230.9a.244.244 0 00-.178.086.912.912 0 01-.328.217.248.248 0 00-.135.129c-.036.082-.035.143.004.181l.004.004a.23.23 0 00.121.117.2.2 0 00.174.004c.069-.029.138-.06.205-.097v1.722c0 .1.03.181.092.245.028.028.102.04.223.04h.01c.119 0 .191-.014.218-.04a.325.325 0 00.092-.245v-2.142a.219.219 0 00-.063-.158.221.221 0 00-.16-.063z" />
                  <path fill="#01cd6a"
                        d="M55.42 234.407c-.844.062-1.732.11-2.645.142v11.235c.7 1.335 1.996 1.419 2.646 0zM58.902 234.051c-.845.104-1.733.218-2.654.284v6.936c.7 1.335 1.995 1.418 2.645 0z" />
                  <path style="line-height:1.25" class="t1" fill="#01cd6a"
                        d="M49.6 226.386c-.145 0-.283.066-.41.199a2.108 2.108 0 01-.759.501.573.573 0 00-.311.298c-.085.191-.082.332.009.42l.009.01c.055.122.15.212.28.27a.461.461 0 00.402.01c.159-.069.318-.142.474-.227v3.983c0 .23.07.418.212.565.065.064.236.094.515.094h.022c.276-.002.444-.033.506-.094.143-.14.212-.328.212-.565v-4.953a.506.506 0 00-.144-.366.511.511 0 00-.37-.145z" />
                  <path style="line-height:1.25" class="t3" fill="#01cd6a"
                        d="M56.464 226.483c-.034.003-.095.01-.181.016-.775.086-1.402.406-1.877.96-.51.6-.761 1.39-.754 2.368.007.916.23 1.647.668 2.191.39.483 1.043.803 1.963.961l.113.016c.01.003.023.003.037 0 .014.003.025.003.031 0 .004.003.012.003.026 0a.07.07 0 00.035 0 .672.672 0 01.113-.016c.858-.068 1.514-.389 1.965-.96.438-.552.659-1.282.662-2.192-.003-.996-.256-1.785-.756-2.367-.468-.544-1.092-.865-1.87-.961l-.175-.016zm0 .852c.383-.003.708.204.977.621.272.41.408 1.005.408 1.783 0 .765-.136 1.352-.408 1.762-.293.458-.618.687-.977.687-.368 0-.699-.229-.992-.687-.265-.41-.398-.997-.398-1.762 0-.778.133-1.373.398-1.783.269-.417.6-.625.992-.621z" />
                  <path style="line-height:1.25" class="t2" fill="#01cd6a"
                        d="M45.92 226.707c-.147 0-.285.065-.411.198a2.108 2.108 0 01-.76.502.573.573 0 00-.31.298c-.085.191-.082.332.008.42l.01.009c.055.123.15.212.28.27a.461.461 0 00.401.01c.16-.068.319-.142.474-.226v3.983c0 .23.07.418.213.564.064.065.235.095.514.095h.023c.276-.002.444-.033.506-.095.143-.14.212-.327.212-.564v-4.954a.506.506 0 00-.145-.366.511.511 0 00-.37-.144z" />
            </g>
      </svg>
</div>
