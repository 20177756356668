<div class='flex flex-col items-center'>

  <div class='align-container flex flex-col overflow-hidden mt-5 max-w-[1080px]
              w-full lg:w-11/12 xl:w-9/12 2xl:w-8/12'>

    <div class="flex flex-row px-3">
      <mat-form-field class="text-xs w-full max-w-72"
                      appearance='outline'>
        <mat-label class='code-none text-sm'>
          Buscar miembros
        </mat-label>
        <input class="text-sm"
               matInput
               (keyup)='filterUser($event)'
               placeholder='username'
        />
      </mat-form-field>
    </div>

    <div class="flex flex-row flex-wrap">
      <ng-container *ngFor='let user of users; let i = index'>
        <div class='w-6/12 sm:w-4/12 md:w-3/12 lg:w-2/12 p-3'>
          <a class='user-card flex flex-col p-5 no-underline shadow-md
                    hover:shadow-none rounded items-center overflow-hidden'
             [routerLink]="'/users/' + user?.username">
            <img class='w-14 h-14 max-w-14 max-h-14 rounded-full mb-5 overflow-hidden'
                 (error)="handlerAvatarImgError(i)"
                 [src]='getUserAvatar(user)'
                 [alt]='(user?.username || "") + " avatar"'/>
            <div class="flex flex-col items-center">
              <span class='top-user-name text-sm whitespace-nowrap'>"{{ user?.username }}"</span>

              <div class="flex flex-col">
                <span class='flex flex-row info-item text-xs mt-3 items-center whitespace-nowrap'>
                  <i class='material-icons text-base'>mode_comment</i>&#160;{{ user?.comments }}
                  comentarios
                </span>
                <span class='flex flex-row info-item text-xs mt-3 items-center whitespace-nowrap'>
                  <i class='material-icons text-base'>format_quote</i>&#160;{{ user?.posts }}
                  artículos
                </span>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

  </div>
</div>
