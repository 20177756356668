<app-header></app-header>

<app-loading></app-loading>

<div>
  <router-outlet></router-outlet>
</div>

<app-scroll-top></app-scroll-top>

<app-cli></app-cli>

<app-cookies-consent></app-cookies-consent>
