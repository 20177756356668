<div class="cookies-consent fixed right-0 bottom-0 left-0 rounded m-3 p-4 shadow-lg hidden"
     style="border: solid 1px #0001;"
     [ngClass]="{'hidden': !showConsent}">
  <span class="text-sm mb-3">En el sitio actual se están utilizando cookies para mejorar su experiencia.</span>
  <span class="text-sm">
    Si desea conocer un poco más sobre el tema, puede leer las
    <a class="hover:underline font-semibold"
       routerLink="/privacy-policy">políticas de privacidad</a>
    y las
    <a class="hover:underline font-semibold"
       routerLink="/cookies">políticas de cookies</a>
    actualizadas.
  </span>
  <div class="flex flex-row justify-end">
    <button (click)="consentCookies()"
            class="bc-button p-2" mat-button>Aceptar
    </button>
  </div>
</div>
