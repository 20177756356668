<div class="bc-card-modal">
  <span class="title">Para suscribirse a nuestra newsletter</span>
  <div >
    <form [formGroup]="subscribeForm"
          (ngSubmit)="ok()">
      <div >
        <div class="card">
          <mat-form-field appearance="outline">
            <mat-label class="code-none">
              <span class="code-1">var </span>
              <span class="code-var"> Correo </span> =
            </mat-label>
            <input matInput
                   type="email"
                   placeholder="Email"
                   formControlName="email">
          </mat-form-field>
          <div class='button-container'>
            <button mat-button
                    class="bc-button"
                    type="submit">
              <span class="code-1">new</span>
              Subscriptor(
              <span class="code-var"> Correo </span>
              )
            </button>
            <button mat-button
                    (click)='cancel()'
                    class="bc-button secundary">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
